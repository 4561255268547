import {GenerateStyleEntity, Blot, Commands, type BlotName} from '@avvoka/editor'

/**
 * Converts a text transform value into corresponding CSS style string.
 * Handles both text-transform and font-variant properties.
 *
 * @param {('upper'|'lower'|'capital'|'small-caps')} value - The text transformation to apply
 * @returns CSS style string containing text-transform and font-variant
 *
 * @example
 * getTextTransform('upper') // returns '{text-transform: uppercase; font-variant: unset;}'
 * getTextTransform('small-caps') // returns '{text-transform: unset; font-variant: small-caps;}'
 */
export const getTextTransform = (value: 'upper' | 'lower' | 'capital' | 'small-caps'): string => {
  const transformMap = {
    upper: 'uppercase',
    lower: 'lowercase',
    capital: 'capitalize'
  }

  const textTransform = transformMap[value as keyof typeof transformMap] ?? 'unset'
  const fontVariant = value === 'small-caps' ? 'small-caps' : 'unset'

  return `{text-transform: ${textTransform}; font-variant: ${fontVariant};}`
}

/**
 * Generates a CSS font-family string based on the provided font name.
 * Includes appropriate fallback fonts for common font families.
 *
 * @param value - The name of the font to use
 * @returns CSS style string containing font-family
 *
 * @example
 * getFont('arial') // returns '{font-family: Arial, Helvetica, sans-serif;}'
 * getFont('custom-font') // returns '{font-family: custom-font, Arial, Helvetica, sans-serif;}'
 */
export const getFont = (value: string): string => {
  const FONT_FAMILIES = {
    'arial': 'Arial, Helvetica, sans-serif',
    'arial-black': '"Arial Black", Gadget, sans-serif',
    'calibri': 'Calibri, Carlito, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif',
    'comic-sans-ms': '"Comic Sans MS", cursive, sans-serif',
    'courier-new': '"Courier New", Courier, monospace',
    'georgia': 'Georgia, serif',
    'impact': 'Impact, Charcoal, sans-serif',
    'lucida-console': '"Lucida Console", Monaco, monospace',
    'lucida-sans-unicode': '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
    'palatino-linotype': '"Palatino Linotype", FreeSerif, "Book Antiqua", Palatino, serif',
    'tahoma': 'Tahoma, Kalimati, Geneva, sans-serif',
    'times-new-roman': '"Times New Roman", Times, serif',
    'trebuchet-ms': '"Trebuchet MS", Helvetica, sans-serif',
    'verdana': 'Verdana, Geneva, sans-serif'
  } as const

  let fontFamily: string = FONT_FAMILIES[value as keyof typeof FONT_FAMILIES]
  if(!fontFamily && value) fontFamily = `${value}, Arial, Helvetica, sans-serif`
  if(!fontFamily) fontFamily = FONT_FAMILIES['arial']

  return `{font-family: ${fontFamily};}`
}

/**
 * Generates a CSS color style string.
 *
 * @param value - Color value (hex, rgb, named color)
 * @returns CSS style string for color
 *
 * @example
 * getColor('#FF0000') // returns '{color: #FF0000;}'
 */
export const getColor = (value: string) => `{color: ${value};}`

/**
 * Generates a CSS style string for bold text.
 *
 * @returns CSS style string for bold font-weight
 *
 * @example
 * getBold() // returns '{font-weight: bold;}'
 */
export const getBold = () => `{font-weight: bold;}`

/**
 * Generates a CSS style string for italic text.
 *
 * @returns CSS style string for italic font-style
 *
 * @example
 * getItalic() // returns '{font-style: italic;}'
 */
export const getItalic = () => `{font-style: italic;}`

/**
 * Generates a CSS style string for underlined text.
 *
 * @returns CSS style string for underline text-decoration
 *
 * @example
 * getUnderline() // returns '{text-decoration: underline;}'
 */
export const getUnderline = () => `{text-decoration: underline;}`

/**
 * Generates a CSS style string for subscript or superscript text.
 *
 * @param {'sub' | 'super'} mode - Whether to generate subscript or superscript styles
 * @returns CSS style string for vertical alignment and font size
 *
 * @example
 * getSubSup('sub') // returns '{vertical-align: sub; font-size: .83em;}'
 */
export const getSubSup = (mode: 'sub' | 'super') =>
  `{vertical-align: ${mode}; font-size: .83em;}`

/**
 * Generates a CSS margin style string from a blot's margin attributes.
 *
 * @param {Blot} blot - The blot containing margin attributes
 * @returns CSS style string for margins
 * @private
 */
const getBlockMargins = (blot: Blot): string => {
  const getMarginValue = (property: string): string =>
    blot.attributes[property] ? `${blot.attributes[property]}px` : 'unset'

  return `{
    margin-top: ${getMarginValue('data-avv-margin-top')};
    margin-right: ${getMarginValue('data-avv-margin-right')};
    margin-bottom: ${getMarginValue('data-avv-margin-bottom')};
    margin-left: ${getMarginValue('data-avv-margin-left')};
  }`
}

/**
 * Map of handler functions for different blot types.
 * Each handler processes a specific type of blot and generates appropriate style commands.
 *
 * @private
 */
const blotHandlers = {
  color: (blot: Blot, commands: Commands) =>
    commands.spawn({ style: getColor(`#${blot.attributes['data-value']}`) }),

  bold: (_: Blot, commands: Commands) =>
    commands.spawn({ style: getBold() }),

  italic: (_: Blot, commands: Commands) =>
    commands.spawn({ style: getItalic() }),

  underline: (_: Blot, commands: Commands) =>
    commands.spawn({ style: getUnderline() }),

  sub: (_: Blot, commands: Commands) =>
    commands.spawn({ style: getSubSup('sub') }),

  sup: (_: Blot, commands: Commands) =>
    commands.spawn({ style: getSubSup('super') }),

  textTransform: (blot: Blot, commands: Commands) =>
    commands.spawn({
      style: getTextTransform(blot.attributes['data-value'] as Parameters<typeof getTextTransform>[0]),
    }),

  font: (blot: Blot, commands: Commands) =>
    commands.spawn({
      style: getFont(blot.attributes['font'])
    }),

  fontSize: (blot: Blot, commands: Commands) => {
    const size = `${+blot.attributes['size']}pt`
    commands.spawn({ style: `{font-size: ${size};}` })
    commands.spawn({
      style: `avv-numbered:has($IDENT$)::before {font-size: ${size};}`,
      custom: true
    })
  },
  block: (blot: Blot, commands: Commands) =>
    commands.spawn(new GenerateStyleEntity(getBlockMargins(blot), blot))
} satisfies Partial<Record<BlotName, (blot: Blot, commands: Commands) => void>>

/**
 * Generates style entities for an array of blots by applying appropriate styling handlers.
 * This function processes various text formatting blots (color, bold, italic, etc.) and
 * generates corresponding style entities.
 *
 * @param blots - Array of blots to process
 * @param commands - Command interface for spawning style entities
 *
 * @example
 * // Process array of blots and generate styles
 * generateBlotStyles([
 *   { statics: { blotName: 'bold' } },
 *   { statics: { blotName: 'color' }, attributes: { 'data-value': 'FF0000' } }
 * ], commandsInstance)
 *
 * @remarks
 * Supported blot types:
 * - color: Applies text color
 * - bold: Makes text bold
 * - italic: Makes text italic
 * - underline: Adds underline
 * - sub/sup: Creates subscript/superscript
 * - textTransform: Handles text case transformations
 * - font: Sets font family
 * - fontSize: Adjusts font size
 * - block: Handles margin settings
 */
export const generateBlotStyles = (blots: Blot[], commands: Commands): void => {
  blots.forEach(blot => {
    const handler = blotHandlers[blot.statics.blotName as keyof typeof blotHandlers]
    if (handler) {
      handler(blot, commands)
    }
  })
}
